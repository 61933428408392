body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    @include font-smoothing;
    @include smooth-scroll;
    font-family: $faktPro;
    font-size: 20px;
    line-height: 1.5;
    color: #000;
    letter-spacing: -0.2px;
    transition: opacity 1s;
    opacity: 1;
    &.loading {
        opacity: 0;
        transition: opacity 1s; } }
*::selection {
    background-color: #efefef; }


// img
// 	height: auto

a {
	text-decoration: none;
	color: inherit; }

ul {
	margin: 0;
	padding: 0 {
    list-style-type: none; } }

button {
    background-color: transparent;
    font-size: 0;
    font-family: $faktPro; }

.out {
    overflow: hidden;
    min-height: 100vh;
    position: relative; }

.arrow {
    visibility: hidden;
    opacity: 0;
    @include arrowTrans;
    display: inline-block;
    padding-left: 5px; }


.loader {
    margin: 0 auto;
    height: 30px;
    width: 30px;
    background-color: #ff6600;
    border-radius: 50%;
    animation: pulsing 2s infinite;
    transition: opacity .3s;
    .stop {
        opacity: 0; } }


@keyframes pulsing {
    0% {
        opacity: 0;
        transform: scale(.5); }
    50% {
        opacity: 1;
        transform: scale(1); }
    100% {
        opacity: 0;
        transform: scale(.5); } }
