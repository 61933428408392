.project {
    padding-top: 157px;
    padding-bottom: 134px;
    .dark-theme & {
        background-color: $text-dark;
        color: $main-menu-color;
        .label {
            color: $main-menu-color-muted; }
        .social__link {
            color: $main-menu-color; } }
    @include sm {
        padding-top: $main-margin-top-sm;
        padding-bottom: 130px; }
    &__top {
        margin-bottom: 350px;
        @include lg {
            margin-bottom: 250px; }
        @include md {
            margin-bottom: 150px; }
        @include sm {
            margin-bottom: 66px; }
        p {
            max-width: 350px; }
        .label {
            margin-top: 24px;
            font-size: 20px; } }

    &__images {
        margin-bottom: 20px;
        .row {
            padding-bottom: 60px;
            @include sm {
                padding-bottom: 25px; } } }
    &-picture {
        @include cover;
        @include sm {
            margin-bottom: 15px; } }
    &__links-group {
        p {
            line-height: 1.4; }
        @include sm {
            margin-bottom: 34px; } }
    .project_link {
        margin-bottom: 40px;
        &:hover .arrow {
            visibility: visible;
            opacity: 1;
            @include arrowTrans; } }
    .mt-30 {
        margin-top: 30px;
        @include sm {
            margin-top: 0; } } }

.dark-theme {
    background-color: $text-dark; }

// ===== IMAGES =====

.images-small {
    .project-picture {
        padding-bottom: 126%; }
    @include sm {
        flex-flow: column; } }

.images-middle {
    .project-picture {
        padding-bottom: 66%; }
    @include sm {
        flex-flow: column; } }

.images-big {
    .project-picture {
        padding-bottom: 52%; } }

.margin-bottom-30 {
    margin-bottom: 30px;
    @include sm {
        margin-bottom: 0; } }

// ===== LINKS =====

.social {
    .label {
        font-size: 17px;
        line-height: 23px;
        color: $light-grey; }
    &__link {
        display: block;
        line-height: 25px;
        color: $text-dark; }
    p {
        line-height: 1.4; } }


@include sm {
    .order-sm-1 {
        order: 1; }
    .order-sm-2 {
        order: 2; }
    .order-sm-3 {
        order: 3; }
    .order-sm-4 {
        order: 4; } }

// ===== NEXT-PROJECT =====

.next-project {
    margin-top: 160px;
    @include lg {
        margin-top: 100px; }
    @include md {
        margin-top: 60px; }
    @include sm {
        margin-top: 10px; }
    .label {
        font-size: 20px;
        line-height: 28px;
        color: $text-dark; } }

.next-project-text {
    margin-top: 30px; }

.next-project-link {
    display: block;
    font-size: 35px;
    line-height: 1.42;
    margin-top: 6px;
    padding-right: 15px;
    @include sm {
        margin-top: 5px; }
    &:hover .arrow {
        visibility: visible;
        opacity: 1; } }

.next-project-picture {
    max-width: 340px;
    padding-bottom: 61%;
    @include cover;
    @include sm {
        padding-bottom: 200px; } }

