.menu {
    position: fixed;
    display: none;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $text-dark;
    overflow: hidden;
    @include vertical;
    text-align: left;
    &.is-open {
        display: none !important; }
    @include sm {
        overflow: scroll;
        height: 100vh;
        padding-top: $main-margin-top-sm;
        padding-bottom: 0;
        &.is-open {
            display: block !important; } }
    &__container {
        height: 100%; }
    &__item {
        font-size: 50px;
        line-height: 1;
        margin-bottom: 3vh;
        color: $main-menu-color;
        @include css-lock(35,50,768,1400);
        transition: color 1.3s 0.2s;
        &:last-child {
            margin-bottom: 60px; }
        @include sm {
            a {
                font-size: 35px; } }
        &.is-active {
            a {
                color: $orange;
                border-bottom: 1px solid $orange; } } }
    &__motto {
        max-width: 320px;
        font-size: 17px;
        line-height: 23px;
        color: $main-menu-color-muted; }
    .label {
        color: $main-menu-color-muted;
        @include sm {
            font-size: 17px; } }
    .contacts-list {
        @include sm {
            margin-bottom: 30px; } }
    .email,
    .adress,
    .phone {
        color: $main-menu-color;
        transition: color 0.7s 0.2s;
        @include sm {
            font-size: 20px; } } }

.hovered {
    .menu__item {
        color: $main-menu-color-muted;
        transition: color 1.3s 0.2s; } }

.light {
    color: #fff !important;
    transition: color 1.3s 0.2s;
    .menu__item {
        color: #fff !important;
        transition: color 1.3s 0.2s; } }



// ===== MAIN MENU ROWS =====

.header,
.menu-bottom {
    .container-inner {
        padding: 0 28px 0 36px; } }

.menu .wrapper {
    min-height: 700px;
    vertical-align: middle;
    display: inline-block; }


.margin-top-3vh {
    margin-top: 3vh; }

.margin-top-6vh {
    margin-top: 6vh; }

// ===== TOP / BOTTOM MENUS =====

.menu-top {
    position: relative;
    z-index: 10;
    @include sm {
        .header.fixed & {
            background-color: $text-dark;
            transition: background-color 0.1s 0.5s;
            padding-bottom: 16px; } } }


.menu-top-container {
    align-items: center;
    &>* {
        display: flex;
        align-items: center; } }

.nav {
    &-item:first-child {
        margin-right: 38px;
        @include sm {
            margin-right: 20px; } }
    &-item--wider {
        margin-right: 54px !important;
        @include md {
            margin-right: 30px !important; } }
    &-item {
        display: inline-block;
        position: relative;
        overflow: hidden;
        @include sm {
            line-height: 1; } }
    &-item:hover .arrow {
        visibility: visible;
        opacity: 1;
        @include arrowTrans; } }

.menu-top {
    .nav-item {
        color: $text-dark;
        &.is-active {
            color: $orange;
            &:after {
                transform: translateX(0);
                transition: transform 0.2s; } }
        .dark-theme & {
            color: $main-menu-color; }
        .dark-theme &.is-active {
            color: $main-menu-color-muted; }
        &:after {
            position: absolute;
            top: 90%;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: $text-dark;
            content: '';
            transform: translateX(-105%);
            transition: transform 0.2s; }
        &:hover:after {
            transform: translateX(0);
            transition: transform 0.2s; }
        &.is-active:after {
            background-color: $orange; }
        .dark-theme &:after {
            background-color: $main-menu-color; }
        .dark-theme &.is-active:after {
            background-color: $main-menu-color-muted; } } }

.logo {
    display: flex;
    max-width: 88px;
    max-height: 30px;
    position: relative;
    z-index: 10000;
    svg {
        width: 88px; }
    &.logo-grey {
        @include sm {
            svg {
                fill: $main-menu-color;
                display: inline-block; } } }
    .dark-theme & {
        svg {
            fill: $main-menu-color; } }
    // +md
    //     margin-left: -15px
    @include sm {
        padding-top: $header-top-padding-sm;
        svg {
            height: 20px;
            width: 75px; } } }

.menu {
    .menu-bottom {
        position: absolute;
        z-index: 3000;
        bottom: 0;
        padding-bottom: 32px;
        left: 0;
        right: 0;
        font-size: 20px;
        text-align: left;
        .email {
            line-height: 0.8;
            color: $light-grey;
            transition: color 0.7s 0.2s;
            display: inline-block;
            a {
                border-bottom: 1px solid;
                line-height: 0.7;
                display: inline-block; } }
        @include sm {
            position: relative;
            padding-bottom: 10px;
            padding-top: 105px;
            font-size: 20px;
            line-height: 1; } }
    .lang {
        font-size: 20px;
        color: $main-menu-color-muted;
        transition: color 0.7s 0.2s;
        @include sm {
            font-size: 20px; }
        &__item.is-active {
            color: $main-menu-color;
            transition: color 0.7s 0.2s; }
        &--mob {
            padding-bottom: 64px;
            padding-top: 10px; } }
    .adress {
        position: relative;
        &:after {
            content: '↗';
            margin-left: 4px;
            visibility: hidden;
            opacity: 0;
            @include arrowTrans; }
        &:hover {
            &:after {
                visibility: visible;
                opacity: 1; } } } }


.time {
    font-size: 17px;
    color: $light-grey;
    transition: color 0.7s 0.2s; }

.margin-110 {
    margin-bottom: 10vh; }

.margin-64 {
    margin-bottom: 64px; }

.jobs {
    margin-bottom: 54px;
    color: $main-menu-color;
    @include sm {
        font-size: 20px; }
    h5 {
        font-size: 1em;
        font-weight: 400;
        color: $main-menu-color-muted; } }

.social {
    &__item {
        display: inline-block;
        color: $light-grey;
        transition: color 0.7s 0.2s;
        @include sm {
            line-height: 1; }
        &:hover .arrow {
            visibility: visible;
            opacity: 1; }
        @include sm {
            margin-right: 20px; } }
    &__item:first-child {
        margin-right: 38px; } }


// ===== MENU-TRIGGER =====

.menu-trigger {
    position: absolute;
    top: -8px;
    right: 30px;
    cursor: pointer;
    z-index: 10000;
    padding: 20px;
    padding-right: 0;
    &:after {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        width: 15px;
        left: 100%; }
    @include sm {
        margin-right: -$header-top-padding-sm;
        padding: $header-top-padding-sm;
        padding-right: 0;
        top: 0; }
    &-text {
        font-size: 20px; }

    &.menu-is-open {
        .text-close {
            display: none; }
        .text-open {
            display: block; } } }

.dark-theme {
    .text-close {
        color: $main-menu-color; } }

.text-open {
    display: none;
    color: $main-menu-color; }
