.slider-main {
    background-color: $text-dark;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include sm {
        position: relative;
        height: 100vh;
        min-height: 500px; } }

.slider-title {
    font-size: 20px;
    color: $orange;
    border-bottom: 1px solid;
    font-weight: 400;
    margin-top: 140px;
    line-height: 1.15;
    display: inline-block;
    @include lg {
        margin-top: 100px; }
    @include md {
        display: none; } }

.slider {
    width: 140%;
    z-index: 4;
    opacity: 0;
    margin-left: -65px;
    visibility: hidden;
    transition: opacity 2s, visibility 2s;
    @include r(840) {
        margin-left: 0;
        width: 100%; }
    @include sm {
        margin: 0; }
    .owl-stage-outer {
        overflow: visible; }
    &-container {
        padding-top: 140px;
        @include vertical;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include sm {
            padding-top: 0; } }
    &-wrapper {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        max-width: 1430px;
        width: 100%;
        @include r(1429) {
            max-width: 100% !important; } } }
.slide {
    transition: transform 0.5s;
    font-size: 20px;
    @include sm {
        padding: 0 8px 0; }
    @include hover {
        .slide__img {
            transform: translateY(42px);
            @include sm {
                transform: translateY(0); } }
        // .slide__text
        //     transform: translateY(-42px)
        //     +sm
 }        //         transform: translateY(0)
    &__img {
        padding-bottom: 410px;
        position: relative;
        @include cover;
        z-index: 3;
        background-color: #EEE;
        transition: transform 0.5s;
        @include lg {
            padding-bottom: 310px; }
        @include sm {
            padding-bottom: 270px;
            width: 250px; } }
    &__text {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        color: $light-grey;
        text-align: left;
        // transition: transform 0.7s
        &:hover .arrow {
            visibility: visible;
            opacity: 1; }
        @include sm {
            top: calc(100% + 16px);
            left: 8px;
            z-index: 100; } } }


.owl-item {
    min-width: 200px;
    max-width: 613px;
    @include r(840) {
        max-width: 100%;
        min-width: 0; } }




