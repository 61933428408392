.filter {
    margin: 168px auto 136px;
    // +css-lock(17,20,767,1400)
    @include sm {
        margin-top: $main-margin-top-sm;
        margin-bottom: 0;
        font-size: 20px; }
    &__main {
        position: relative;
        padding-top: 60px;
        @include sm {
            padding-top: 0; } }
    &__layout {
        text-align: center;
        margin: 0 -80px;
        transition: opacity 1s, visibility;
        height: auto !important;
        @include sm {
            height: auto !important;
            padding-left: 0;
            margin-left: -15px;
            margin-right: -15px; } }
    &__label {
        font-size: 17px;
        color: $light-grey;
        text-align: left;
        &--dark {
            color: $main-menu-color-muted; } }
    &__buttons {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: column;
        z-index: 1;
        &--main {
            user-select: none;
            @include sm {
                position: static;
                margin-bottom: 52px; } }
        &--filter {
            margin-top: 168px;
            left: 36px;
            @include sm {
                left: 15px;
                margin-top: $main-margin-top-sm; } } }
    &__btn {
        font-size: 20px;
        color: $light-grey;
        text-align: left;
        line-height: 1.4;
        &.is-active {
            color: $main-menu-color-muted; }
        &--selected {
            color: #333;
            // &.is-change
 }            //     color: #abadb0
        &[data-filter="*"] {
            flex-grow: 0;
            position: relative;
            &:after {
                position: absolute;
                top: 85%;
                left: 0;
                right: 0;
                width: 25px;
                height: 1px;
                background-color: $light-grey;
                content: ''; } } }
    &__item {
        position: relative;
        display: inline-block;
        padding: 0 80px 60px;
        position: static !important;
        top: auto !important;
        left: auto !important;
        transition: opacity .5s ease-in-out, transform .5s ease-in-out;
        &.is-animate {
            opacity: 0;
            transform: translateY(50px); }
        @include sm {
            padding: 0 15px 10px;
            max-width: 100%; } }

    &__image {
        @include cover;
        height: 247px;
        position: relative;
        z-index: 3;
        transition: 2.5s transform;
        background-color: #eee;
        @include md {
            height: 190px; }
        img {
            height: 100%;
            display: block; }
        // &:hover
        //     transform: translateY(42px)
        //     transition: 0.7s transform
        //     +sm
        //         transform: translatey(0)
        &--small {
            width: 164px;
            @include sm {
                padding-bottom: 120%; } }
        &--middle {
            width: 350px;
            @include sm {
                max-width: 100%;
                padding-bottom: 72%; } }
        &--big {
            width: 439px;
            @include sm {
                max-width: 100%;
                padding-bottom: 58%; } } }
    &__next {
        text-align: center;
        position: relative;
        .icon {
            fill: $orange;
            font-size: 0.75em; }
        @include sm;
        margin-top: 54px;
        margin-bottom: 64px; }



    // ===== BUTTONS POPUP =====

    &__filter {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        position: fixed;
        z-index: 100;
        transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
        background-color: $text-dark;
        &.is-active {
            opacity: 1;
            visibility: visible;
            overflow: hidden; } } }




