.container-inner {
    padding: 0 36px;
    position: relative;
    @include sm {
        padding: 0 15px; } }

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    @include sm {
        margin-right: 0;
        margin-left: 0; } }

.col-24 {
    flex: 0 0 24%; }

.col-56 {
    flex: 0 0 55%; }

.col-31 {
    flex: 0 0 31%; }

.col-45 {
    flex: 0 0 45%; }

.col-46 {
    flex: 0 0 46%; }

.col-50 {
    flex: 0 0 50%; }

.col-55 {
    flex: 0 0 55%; }

.col-76 {
    flex: 0 0 76%; }

[class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
    @include sm {
        flex: 0 0 100%;
        padding-right: 0;
        padding-left: 0; } }


.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    @include sm {
        padding-right: 0;
        padding-left: 0; } }

.d-sm-block {
    display: none;
    @include sm {
        display: block; } }

.d-md-block {
    display: none;
    @include md {
        display: block; } }

@include lg {
    .d-lg-none {
        display: none; }
    .col-lg-55 {
        flex: 0 0 55%; }
    .col-lg-76 {
        flex: 0 0 76%; }
    .col-lg-45 {
        flex: 0 0 45%; } }
@include md {
    .d-md-none {
        display: none; }
    .col-md-100 {
        flex: 0 0 100%; }
    .col-md-76 {
        flex: 0 0 76%; }
    .col-md-55 {
        flex: 0 0 55%; } }



@include sm {
    .d-sm-none {
        display: none; }
    [class$="vh"] {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0; } }

