//FONTS
@mixin fontpath($name, $name1, $file) {
	src: local($name), local($name1), url("../fonts/" + $file + ".woff2") format("woff2"), url("../fonts/" + $file + ".woff") format("woff"); }
@mixin fontdefault($style, $weight, $name) {
	font-family: $name;
	font-style: $style;
	font-weight: $weight; }

$faktPro: 'FaktPro';

@font-face {
	@include fontdefault(normal, 400, $faktPro);
	@include fontpath('FaktPro Normal', 'FaktPro-Normal', FaktPro-Normal); }
