// [data-image*="parent"]
//     overflow: hidden
//     transform: translate3d(-100%,0,0)
//     &.is-active
//         +gpu
//         transition-delay: 0.5s
//         [data-image*="child"]
//             // display: inline-block
//             transition-delay: 0.5s
//             +gpu
//             img
//                 max-width: 100%

// [data-image*="child"]
// 	transform: translate3d(101%,0,0)

// [data-image]
// 	transition: transform 1s cubic-bezier(0.8,0,0.2,1)

// .text-row
//     position: relative
//     transform: translateY(80px)
//     opacity: 0

// .js-title, .js-title-secondary
//     .text-row
//         position: relative
//         transform: translateY(0)
//         opacity: 1
//     span
//         position: relative
//         transform: translateX(30px)
//         opacity: 0
//         display: inline-block

[data-image*="parent"] {
    position: relative;
    opacity: 0;
    transform: translate3d(0,20px,0);
    &.is-active {
        @include gpu;
        transition-delay: 0.5s;
        opacity: 1;
        // [data-image*="child"]
        //     // display: inline-block
        //     transition-delay: 0.5s
        //     +gpu
        //     img
 } }        //         max-width: 100%

[data-image*="child"] {
 }	// transform: translate3d(0,-101%,0)

[data-image] {
	transition: transform .7s ease-out, opacity .7s ease-out; }

.text-row {
    position: relative;
    transform: translateY(80px);
    opacity: 0; }

// .js-title, .js-title-secondary
//     .text-row
//         position: relative
//         transform: translateY(0)
//         opacity: 1
//     span
//         position: relative
//         transform: translateX(30px)
//         opacity: 0
//         display: inline-block

.sr-up {
    transform: translateY(50px); }

