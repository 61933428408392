@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

a { text-decoration: none; }

@font-face { font-family: "FaktPro"; font-style: normal; font-weight: 400; src: local("FaktPro Normal"), local("FaktPro-Normal"), url("../fonts/FaktPro-Normal.woff2") format("woff2"), url("../fonts/FaktPro-Normal.woff") format("woff"); }

body { margin: 0; padding: 0; min-width: 320px; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; -webkit-overflow-scrolling: touch; font-family: "FaktPro", Helvetica, Arial, sans-serif; font-size: 20px; line-height: 1.5; color: #000; letter-spacing: -0.2px; transition: opacity 1s; opacity: 1; }

body.loading { opacity: 0; transition: opacity 1s; }

*::selection { background-color: #efefef; }

a { text-decoration: none; color: inherit; }

ul { margin: 0; padding: 0; padding-list-style-type: none; }

button { background-color: transparent; font-size: 0; font-family: "FaktPro", Helvetica, Arial, sans-serif; }

.out { overflow: hidden; min-height: 100vh; position: relative; }

.arrow { visibility: hidden; opacity: 0; transition: 0.4s opacity, 0.4s visibility; display: inline-block; padding-left: 5px; }

.loader { margin: 0 auto; height: 30px; width: 30px; background-color: #ff6600; border-radius: 50%; animation: pulsing 2s infinite; transition: opacity .3s; }

.loader .stop { opacity: 0; }

@keyframes pulsing { 0% { opacity: 0;
    transform: scale(0.5); }
  50% { opacity: 1;
    transform: scale(1); }
  100% { opacity: 0;
    transform: scale(0.5); } }

.container-inner { padding: 0 36px; position: relative; }

@media only screen and (max-width: 767px) { .container-inner { padding: 0 15px; } }

.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

@media only screen and (max-width: 767px) { .row { margin-right: 0; margin-left: 0; } }

.col-24 { flex: 0 0 24%; }

.col-56 { flex: 0 0 55%; }

.col-31 { flex: 0 0 31%; }

.col-45 { flex: 0 0 45%; }

.col-46 { flex: 0 0 46%; }

.col-50 { flex: 0 0 50%; }

.col-55 { flex: 0 0 55%; }

.col-76 { flex: 0 0 76%; }

[class*="col-"] { padding-right: 15px; padding-left: 15px; }

@media only screen and (max-width: 767px) { [class*="col-"] { flex: 0 0 100%; padding-right: 0; padding-left: 0; } }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; padding-right: 10px; padding-left: 10px; }

@media only screen and (max-width: 767px) { .col { padding-right: 0; padding-left: 0; } }

.d-sm-block { display: none; }

@media only screen and (max-width: 767px) { .d-sm-block { display: block; } }

.d-md-block { display: none; }

@media only screen and (max-width: 1023px) { .d-md-block { display: block; } }

@media only screen and (max-width: 1279px) { .d-lg-none { display: none; }
  .col-lg-55 { flex: 0 0 55%; }
  .col-lg-76 { flex: 0 0 76%; }
  .col-lg-45 { flex: 0 0 45%; } }

@media only screen and (max-width: 1023px) { .d-md-none { display: none; }
  .col-md-100 { flex: 0 0 100%; }
  .col-md-76 { flex: 0 0 76%; }
  .col-md-55 { flex: 0 0 55%; } }

@media only screen and (max-width: 767px) { .d-sm-none { display: none; }
  [class$="vh"] { margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0; } }

.owl-carousel { display: none; width: 100%; -webkit-tap-highlight-color: transparent; position: relative; z-index: 1; }

.owl-carousel .owl-stage { position: relative; -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after { content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }

.owl-carousel .owl-stage-outer { position: relative; overflow: hidden; -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); position: relative; min-height: 1px; float: left; -webkit-backface-visibility: hidden; -webkit-tap-highlight-color: transparent; -webkit-touch-callout: none; }

.owl-carousel .owl-item img { display: block; width: 100%; }

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled { display: none; }

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next { cursor: pointer; cursor: hand; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.owl-carousel .owl-dot { cursor: pointer; cursor: hand; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.owl-carousel.owl-loaded { display: block; }

.owl-carousel.owl-loading { opacity: 0; display: block; }

.owl-carousel.owl-hidden { opacity: 0; }

.owl-carousel.owl-refresh .owl-item { visibility: hidden; }

.owl-carousel.owl-drag .owl-item { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.owl-carousel.owl-grab { cursor: move; cursor: grab; }

.owl-carousel.owl-rtl { direction: rtl; }

.owl-carousel.owl-rtl .owl-item { float: right; }

.no-js .owl-carousel { display: block; }

.owl-carousel .animated { -webkit-animation-duration: 1000ms; animation-duration: 1000ms; -webkit-animation-fill-mode: both; animation-fill-mode: both; }

.owl-carousel .owl-animated-in { z-index: 0; }

.owl-carousel .owl-animated-out { z-index: 1; }

.owl-carousel .fadeOut { -webkit-animation-name: fadeOut; animation-name: fadeOut; }

@-webkit-keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }

@keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }

.owl-height { transition: height 500ms ease-in-out; }

.owl-carousel .owl-item .owl-lazy { opacity: 0; transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy { -webkit-transform-style: preserve-3d; transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper { position: relative; height: 100%; background: #000; }

.owl-carousel .owl-video-play-icon { position: absolute; height: 80px; width: 80px; left: 50%; top: 50%; margin-left: -40px; margin-top: -40px; background: url("owl.video.play.png") no-repeat; cursor: pointer; z-index: 1; -webkit-backface-visibility: hidden; transition: -webkit-transform 100ms ease; transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover { -webkit-transform: scale(1.3, 1.3); -ms-transform: scale(1.3, 1.3); transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon { display: none; }

.owl-carousel .owl-video-tn { opacity: 0; height: 100%; background-position: center center; background-repeat: no-repeat; background-size: contain; transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame { position: relative; z-index: 1; height: 100%; width: 100%; }

.header { position: fixed; top: 0; left: 0; right: 8px; padding: 36px 0 10px; z-index: 3000; }

@media only screen and (max-width: 767px) { .header { position: relative; } }

@media only screen and (max-width: 767px) { .header .container-inner { padding: 0 7px 0 15px; } }

.header .container-inner { position: relative; z-index: 500; }

.header.fixed { position: fixed; right: 0; }

@media only screen and (max-width: 767px) { .header { padding-top: 0; } }

.footer { padding-bottom: 22px; overflow: hidden; transform: translate3d(0, 0, 0); z-index: 500; margin-right: 8px; }

@media only screen and (max-width: 767px) { .footer .container-inner { padding: 0 7px 0 15px; } }

.dark-theme .footer { color: #abadb0; }

@media only screen and (max-width: 767px) { .footer { padding-bottom: 0; } }

.nav-email { position: relative; display: block; min-width: 200px; overflow: visible; }

.nav-email:after { position: absolute; top: -100%; left: 0; opacity: 0; visibility: hidden; font-size: 20px; content: attr(data-email); }

.no-touch .nav-email:hover { transform: translateY(100%); }

.no-touch .nav-email:hover:after { opacity: 1; visibility: visible; }

.up { font-size: 20px; }

.dark-theme .up { color: #abadb0; }

.menu { position: fixed; display: none; z-index: 5; left: 0; top: 0; width: 100%; height: 100%; background-color: #262626; overflow: hidden; text-align: center; font-size: 0; text-align: left; }

.menu:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }

.menu.is-open { display: none !important; }

@media only screen and (max-width: 767px) { .menu { overflow: scroll; height: 100vh; padding-top: 124px; padding-bottom: 0; }
  .menu.is-open { display: block !important; } }

.menu__container { height: 100%; }

.menu__item { font-size: 50px; line-height: 1; margin-bottom: 3vh; color: #b3b2b9; font-size: 50px; font-size: calc(35px + (50 - 35) * ((100vw - 768px) / (1400 - 768))); transition: color 1.3s 0.2s; }

@media (max-width: 768px) { .menu__item { font-size: 35px; } }

@media (min-width: 1400px) { .menu__item { font-size: 50px; } }

.menu__item:last-child { margin-bottom: 60px; }

@media only screen and (max-width: 767px) { .menu__item a { font-size: 35px; } }

.menu__item.is-active a { color: #ff4d00; border-bottom: 1px solid #ff4d00; }

.menu__motto { max-width: 320px; font-size: 17px; line-height: 23px; color: #646466; }

.menu .label { color: #646466; }

@media only screen and (max-width: 767px) { .menu .label { font-size: 17px; } }

@media only screen and (max-width: 767px) { .menu .contacts-list { margin-bottom: 30px; } }

.menu .email, .menu .adress, .menu .phone { color: #b3b2b9; transition: color 0.7s 0.2s; }

@media only screen and (max-width: 767px) { .menu .email, .menu .adress, .menu .phone { font-size: 20px; } }

.hovered .menu__item { color: #646466; transition: color 1.3s 0.2s; }

.light { color: #fff !important; transition: color 1.3s 0.2s; }

.light .menu__item { color: #fff !important; transition: color 1.3s 0.2s; }

.header .container-inner, .menu-bottom .container-inner { padding: 0 28px 0 36px; }

.menu .wrapper { min-height: 700px; vertical-align: middle; display: inline-block; }

.margin-top-3vh { margin-top: 3vh; }

.margin-top-6vh { margin-top: 6vh; }

.menu-top { position: relative; z-index: 10; }

@media only screen and (max-width: 767px) { .header.fixed .menu-top { background-color: #262626; transition: background-color 0.1s 0.5s; padding-bottom: 16px; } }

.menu-top-container { align-items: center; }

.menu-top-container > * { display: flex; align-items: center; }

.nav-item:first-child { margin-right: 38px; }

@media only screen and (max-width: 767px) { .nav-item:first-child { margin-right: 20px; } }

.nav-item--wider { margin-right: 54px !important; }

@media only screen and (max-width: 1023px) { .nav-item--wider { margin-right: 30px !important; } }

.nav-item { display: inline-block; position: relative; overflow: hidden; }

@media only screen and (max-width: 767px) { .nav-item { line-height: 1; } }

.nav-item:hover .arrow { visibility: visible; opacity: 1; transition: 0.4s opacity, 0.4s visibility; }

.menu-top .nav-item { color: #262626; }

.menu-top .nav-item.is-active { color: #ff4d00; }

.menu-top .nav-item.is-active:after { transform: translateX(0); transition: transform 0.2s; }

.dark-theme .menu-top .nav-item { color: #b3b2b9; }

.dark-theme .menu-top .nav-item.is-active { color: #646466; }

.menu-top .nav-item:after { position: absolute; top: 90%; left: 0; right: 0; width: 100%; height: 1px; background-color: #262626; content: ''; transform: translateX(-105%); transition: transform 0.2s; }

.menu-top .nav-item:hover:after { transform: translateX(0); transition: transform 0.2s; }

.menu-top .nav-item.is-active:after { background-color: #ff4d00; }

.dark-theme .menu-top .nav-item:after { background-color: #b3b2b9; }

.dark-theme .menu-top .nav-item.is-active:after { background-color: #646466; }

.logo { display: flex; max-width: 88px; max-height: 30px; position: relative; z-index: 10000; }

.logo svg { width: 88px; }

@media only screen and (max-width: 767px) { .logo.logo-grey svg { fill: #b3b2b9; display: inline-block; } }

.dark-theme .logo svg { fill: #b3b2b9; }

@media only screen and (max-width: 767px) { .logo { padding-top: 16px; }
  .logo svg { height: 20px; width: 75px; } }

.menu .menu-bottom { position: absolute; z-index: 3000; bottom: 0; padding-bottom: 32px; left: 0; right: 0; font-size: 20px; text-align: left; }

.menu .menu-bottom .email { line-height: 0.8; color: #abadb0; transition: color 0.7s 0.2s; display: inline-block; }

.menu .menu-bottom .email a { border-bottom: 1px solid; line-height: 0.7; display: inline-block; }

@media only screen and (max-width: 767px) { .menu .menu-bottom { position: relative; padding-bottom: 10px; padding-top: 105px; font-size: 20px; line-height: 1; } }

.menu .lang { font-size: 20px; color: #646466; transition: color 0.7s 0.2s; }

@media only screen and (max-width: 767px) { .menu .lang { font-size: 20px; } }

.menu .lang__item.is-active { color: #b3b2b9; transition: color 0.7s 0.2s; }

.menu .lang--mob { padding-bottom: 64px; padding-top: 10px; }

.menu .adress { position: relative; }

.menu .adress:after { content: '↗'; margin-left: 4px; visibility: hidden; opacity: 0; transition: 0.4s opacity, 0.4s visibility; }

.menu .adress:hover:after { visibility: visible; opacity: 1; }

.time { font-size: 17px; color: #abadb0; transition: color 0.7s 0.2s; }

.margin-110 { margin-bottom: 10vh; }

.margin-64 { margin-bottom: 64px; }

.jobs { margin-bottom: 54px; color: #b3b2b9; }

@media only screen and (max-width: 767px) { .jobs { font-size: 20px; } }

.jobs h5 { font-size: 1em; font-weight: 400; color: #646466; }

.social__item { display: inline-block; color: #abadb0; transition: color 0.7s 0.2s; }

@media only screen and (max-width: 767px) { .social__item { line-height: 1; } }

.social__item:hover .arrow { visibility: visible; opacity: 1; }

@media only screen and (max-width: 767px) { .social__item { margin-right: 20px; } }

.social__item:first-child { margin-right: 38px; }

.menu-trigger { position: absolute; top: -8px; right: 30px; cursor: pointer; z-index: 10000; padding: 20px; padding-right: 0; }

.menu-trigger:after { position: absolute; content: ''; top: 0; bottom: 0; width: 15px; left: 100%; }

@media only screen and (max-width: 767px) { .menu-trigger { margin-right: -16px; padding: 16px; padding-right: 0; top: 0; } }

.menu-trigger-text { font-size: 20px; }

.menu-trigger.menu-is-open .text-close { display: none; }

.menu-trigger.menu-is-open .text-open { display: block; }

.dark-theme .text-close { color: #b3b2b9; }

.text-open { display: none; color: #b3b2b9; }

.about { margin-top: 156px; margin-bottom: 134px; }

@media only screen and (max-width: 767px) { .about { margin-top: 124px; margin-bottom: 120px; }
  .about * { font-size: 20px; } }

.about__main-title { max-width: 300px; }

.about__title h3 { margin-bottom: 20px; font-size: 20px; line-height: 1; font-weight: 400; }

@media only screen and (max-width: 1023px) { .about__title h3 { margin-bottom: 20px; margin-top: 60px; } }

.about__title--high h3 { line-height: 1.5; }

.about__list { line-height: 28px; }

@media only screen and (max-width: 767px) { .about__list { color: #abadb0; } }

.about__text { line-height: 1.4; }

.about__text--wide { line-height: 1.5; }

@media only screen and (max-width: 767px) { .about__text--wide { padding-right: 27px; } }

.about__list--muted { margin-top: 40px; color: #abadb0; }

@media only screen and (max-width: 1023px) { .about__list--muted { margin-top: 98px; } }

.about__contacts { margin-bottom: 48px; }

@media only screen and (max-width: 1023px) { .about__contacts { margin-top: 40px; } }

@media only screen and (max-width: 767px) { .about__contacts { display: none; } }

.about .adress:hover .arrow { visibility: visible; opacity: 0; transition: 0.4s opacity, 0.4s visibility; }

.max-width-450 { max-width: 450px; }

.margin-bottom-200 { margin-bottom: 200px; }

.col-76 .about__text { max-width: 440px; }

.margin-bottom-94 { margin-bottom: 94px; }

.margin-bottom-45 { margin-bottom: 45px; }

.margin-bottom-80 { margin-bottom: 80px; }

.max-width-425 { max-width: 425px; }

.margin-bottom-68 { margin-bottom: 68px; }

.padding-right-50 { padding-right: 50px; }

.margin-bottom-30 { margin-bottom: 30px; }

.margin-bottom-65 { margin-bottom: 65px; }

.margin-bottom-170 { margin-bottom: 170px; }

@media only screen and (max-width: 1279px) { .margin-bottom-lg-100 { margin-bottom: 100px; } }

@media only screen and (max-width: 1023px) { .about__main-title { margin-bottom: 42px; }
  .about [class*="margin-bottom"] { margin-top: 0; margin-bottom: 0; }
  .about .m-sm-10 { margin-bottom: -10px; } }

.main .row--first { margin-top: 200px; margin-bottom: 120px; }

@media only screen and (max-width: 767px) { .main .row--first { margin-top: 60px; margin-bottom: 30px; } }

.main .row--motto-top { margin-bottom: 96px; }

@media only screen and (max-width: 767px) { .main .row--motto-top { margin-bottom: 60px; } }

.main .row--project { margin-bottom: 140px; }

@media only screen and (max-width: 1279px) { .main .row--project { margin-bottom: 100px; } }

@media only screen and (max-width: 1023px) { .main .row--project { margin-bottom: 80px; } }

@media only screen and (max-width: 767px) { .main .row--project { margin-bottom: 0; } }

.main__motto-bottom { max-width: 350px; margin-bottom: 100px; }

@media only screen and (max-width: 1279px) { .main__motto-bottom { margin-bottom: 60px; } }

@media only screen and (max-width: 1023px) { .main__motto-bottom { margin-bottom: 40px; } }

.main__motto-bottom p { margin-bottom: 30px; }

.main__motto-link { color: #abadb0; }

.main__contacts { margin-bottom: 40px; }

.main__contacts:last-of-type { margin-bottom: 100px; }

@media only screen and (max-width: 1023px) { .main__contacts:last-of-type { margin-bottom: 80px; } }

@media only screen and (max-width: 767px) { .main__contacts { margin-bottom: 70px; } }

.feature-list, .contacts-list { line-height: 1.5; }

.project_link { position: relative; font-size: 20px; font-weight: 400; }

.no-touch .project_link:hover .arrow { visibility: visible; opacity: 1; }

.align-center { align-items: center; }

.project-container { display: inline-block; }

.no-touch .project-container:hover .arrow { visibility: visible; opacity: 1; }

@media only screen and (max-width: 767px) { .project-container { margin-bottom: 75px; } }

.label { color: #abadb0; font-size: 18px; }

.adress { position: relative; cursor: default; }

.no-touch .adress:hover .arrow { visibility: visible; opacity: 1; }

.image { display: inline-block; font-size: 0; background-color: #EEE; }

@media only screen and (max-width: 767px) { .image { text-align: center; } }

.image img { display: block; }

@media only screen and (max-width: 767px) { .image img { width: auto !important; } }

.main-title { font-size: 40px; font-weight: 400; line-height: 1.25; max-width: 740px; }

@media only screen and (max-width: 767px) { .main-title { font-size: 30px; line-height: 1.5; } }

.padding-top-300 { padding-top: 300px; }

@media only screen and (max-width: 767px) { .padding-top-300 { padding-top: 0; } }

.project { padding-top: 157px; padding-bottom: 134px; }

.dark-theme .project { background-color: #262626; color: #b3b2b9; }

.dark-theme .project .label { color: #646466; }

.dark-theme .project .social__link { color: #b3b2b9; }

@media only screen and (max-width: 767px) { .project { padding-top: 124px; padding-bottom: 130px; } }

.project__top { margin-bottom: 350px; }

@media only screen and (max-width: 1279px) { .project__top { margin-bottom: 250px; } }

@media only screen and (max-width: 1023px) { .project__top { margin-bottom: 150px; } }

@media only screen and (max-width: 767px) { .project__top { margin-bottom: 66px; } }

.project__top p { max-width: 350px; }

.project__top .label { margin-top: 24px; font-size: 20px; }

.project__images { margin-bottom: 20px; }

.project__images .row { padding-bottom: 60px; }

@media only screen and (max-width: 767px) { .project__images .row { padding-bottom: 25px; } }

.project-picture { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; }

@media only screen and (max-width: 767px) { .project-picture { margin-bottom: 15px; } }

.project__links-group p { line-height: 1.4; }

@media only screen and (max-width: 767px) { .project__links-group { margin-bottom: 34px; } }

.project .project_link { margin-bottom: 40px; }

.project .project_link:hover .arrow { visibility: visible; opacity: 1; transition: 0.4s opacity, 0.4s visibility; }

.project .mt-30 { margin-top: 30px; }

@media only screen and (max-width: 767px) { .project .mt-30 { margin-top: 0; } }

.dark-theme { background-color: #262626; }

.images-small .project-picture { padding-bottom: 126%; }

@media only screen and (max-width: 767px) { .images-small { flex-flow: column; } }

.images-middle .project-picture { padding-bottom: 66%; }

@media only screen and (max-width: 767px) { .images-middle { flex-flow: column; } }

.images-big .project-picture { padding-bottom: 52%; }

.margin-bottom-30 { margin-bottom: 30px; }

@media only screen and (max-width: 767px) { .margin-bottom-30 { margin-bottom: 0; } }

.social .label { font-size: 17px; line-height: 23px; color: #abadb0; }

.social__link { display: block; line-height: 25px; color: #262626; }

.social p { line-height: 1.4; }

@media only screen and (max-width: 767px) { .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; } }

.next-project { margin-top: 160px; }

@media only screen and (max-width: 1279px) { .next-project { margin-top: 100px; } }

@media only screen and (max-width: 1023px) { .next-project { margin-top: 60px; } }

@media only screen and (max-width: 767px) { .next-project { margin-top: 10px; } }

.next-project .label { font-size: 20px; line-height: 28px; color: #262626; }

.next-project-text { margin-top: 30px; }

.next-project-link { display: block; font-size: 35px; line-height: 1.42; margin-top: 6px; padding-right: 15px; }

@media only screen and (max-width: 767px) { .next-project-link { margin-top: 5px; } }

.next-project-link:hover .arrow { visibility: visible; opacity: 1; }

.next-project-picture { max-width: 340px; padding-bottom: 61%; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; }

@media only screen and (max-width: 767px) { .next-project-picture { padding-bottom: 200px; } }

.filter { margin: 168px auto 136px; }

@media only screen and (max-width: 767px) { .filter { margin-top: 124px; margin-bottom: 0; font-size: 20px; } }

.filter__main { position: relative; padding-top: 60px; }

@media only screen and (max-width: 767px) { .filter__main { padding-top: 0; } }

.filter__layout { text-align: center; margin: 0 -80px; transition: opacity 1s, visibility; height: auto !important; }

@media only screen and (max-width: 767px) { .filter__layout { height: auto !important; padding-left: 0; margin-left: -15px; margin-right: -15px; } }

.filter__label { font-size: 17px; color: #abadb0; text-align: left; }

.filter__label--dark { color: #646466; }

.filter__buttons { position: absolute; top: 0; left: 0; display: flex; flex-flow: column; z-index: 1; }

.filter__buttons--main { user-select: none; }

@media only screen and (max-width: 767px) { .filter__buttons--main { position: static; margin-bottom: 52px; } }

.filter__buttons--filter { margin-top: 168px; left: 36px; }

@media only screen and (max-width: 767px) { .filter__buttons--filter { left: 15px; margin-top: 124px; } }

.filter__btn { font-size: 20px; color: #abadb0; text-align: left; line-height: 1.4; }

.filter__btn.is-active { color: #646466; }

.filter__btn--selected { color: #333; }

.filter__btn[data-filter="*"] { flex-grow: 0; position: relative; }

.filter__btn[data-filter="*"]:after { position: absolute; top: 85%; left: 0; right: 0; width: 25px; height: 1px; background-color: #abadb0; content: ''; }

.filter__item { position: relative; display: inline-block; padding: 0 80px 60px; position: static !important; top: auto !important; left: auto !important; transition: opacity .5s ease-in-out, transform .5s ease-in-out; }

.filter__item.is-animate { opacity: 0; transform: translateY(50px); }

@media only screen and (max-width: 767px) { .filter__item { padding: 0 15px 10px; max-width: 100%; } }

.filter__image { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; height: 247px; position: relative; z-index: 3; transition: 2.5s transform; background-color: #eee; }

@media only screen and (max-width: 1023px) { .filter__image { height: 190px; } }

.filter__image img { height: 100%; display: block; }

.filter__image--small { width: 164px; }

@media only screen and (max-width: 767px) { .filter__image--small { padding-bottom: 120%; } }

.filter__image--middle { width: 350px; }

@media only screen and (max-width: 767px) { .filter__image--middle { max-width: 100%; padding-bottom: 72%; } }

.filter__image--big { width: 439px; }

@media only screen and (max-width: 767px) { .filter__image--big { max-width: 100%; padding-bottom: 58%; } }

.filter__next { text-align: center; position: relative; margin-top: 54px; margin-bottom: 64px; }

.filter__next .icon { fill: #ff4d00; font-size: 0.75em; }

.filter__filter { top: 0; left: 0; height: 100%; width: 100%; opacity: 0; visibility: hidden; position: fixed; z-index: 100; transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; background-color: #262626; }

.filter__filter.is-active { opacity: 1; visibility: visible; overflow: hidden; }

.slider-main { background-color: #262626; position: absolute; left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; }

@media only screen and (max-width: 767px) { .slider-main { position: relative; height: 100vh; min-height: 500px; } }

.slider-title { font-size: 20px; color: #ff4d00; border-bottom: 1px solid; font-weight: 400; margin-top: 140px; line-height: 1.15; display: inline-block; }

@media only screen and (max-width: 1279px) { .slider-title { margin-top: 100px; } }

@media only screen and (max-width: 1023px) { .slider-title { display: none; } }

.slider { width: 140%; z-index: 4; opacity: 0; margin-left: -65px; visibility: hidden; transition: opacity 2s, visibility 2s; }

@media only screen and (max-width: 840px) { .slider { margin-left: 0; width: 100%; } }

@media only screen and (max-width: 767px) { .slider { margin: 0; } }

.slider .owl-stage-outer { overflow: visible; }

.slider-container { padding-top: 140px; text-align: center; font-size: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; }

.slider-container:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }

@media only screen and (max-width: 767px) { .slider-container { padding-top: 0; } }

.slider-wrapper { display: inline-block; vertical-align: middle; position: relative; max-width: 1430px; width: 100%; }

@media only screen and (max-width: 1429px) { .slider-wrapper { max-width: 100% !important; } }

.slide { transition: transform 0.5s; font-size: 20px; }

@media only screen and (max-width: 767px) { .slide { padding: 0 8px 0; } }

.no-touch .slide:hover .slide__img { transform: translateY(42px); }

@media only screen and (max-width: 767px) { .no-touch .slide:hover .slide__img { transform: translateY(0); } }

.slide__img { padding-bottom: 410px; position: relative; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; z-index: 3; background-color: #EEE; transition: transform 0.5s; }

@media only screen and (max-width: 1279px) { .slide__img { padding-bottom: 310px; } }

@media only screen and (max-width: 767px) { .slide__img { padding-bottom: 270px; width: 250px; } }

.slide__text { display: block; position: absolute; top: 0; left: 0; z-index: 2; color: #abadb0; text-align: left; }

.slide__text:hover .arrow { visibility: visible; opacity: 1; }

@media only screen and (max-width: 767px) { .slide__text { top: calc(100% + 16px); left: 8px; z-index: 100; } }

.owl-item { min-width: 200px; max-width: 613px; }

@media only screen and (max-width: 840px) { .owl-item { max-width: 100%; min-width: 0; } }

[data-image*="parent"] { position: relative; opacity: 0; transform: translate3d(0, 20px, 0); }

[data-image*="parent"].is-active { transform: translate3d(0, 0, 0); transition-delay: 0.5s; opacity: 1; }

[data-image] { transition: transform .7s ease-out, opacity .7s ease-out; }

.text-row { position: relative; transform: translateY(80px); opacity: 0; }

.sr-up { transform: translateY(50px); }
