.footer {
    padding-bottom: 22px;
    overflow: hidden;
    @include gpu;
    z-index: 500;
    margin-right: 8px;
    .container-inner {
        @include sm {
            padding: 0 7px 0 15px; } }
    // margin-right: 7px
    .dark-theme & {
        color: $light-grey; }
    @include sm {
        // padding-bottom: 10px
        padding-bottom: 0; } }

.nav-email {
    position: relative;
    display: block;
    min-width: 200px;
    overflow: visible;
    &:after {
        position: absolute;
        top: -100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        font-size: 20px;
        content: attr(data-email);
 }        // transition: opacity 0.4s 0.2s, visibility 0.4s 0.2s
    @include hover {
        transform: translateY(100%);
        &:after {
            opacity: 1;
            visibility: visible; } } }

.up {
    font-size: 20px;
    .dark-theme & {
        color: $light-grey; } }

