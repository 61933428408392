.about {
    // +css-lock(14,20,768,1400)
    margin-top: 156px;
    margin-bottom: 134px;
    @include sm {
        margin-top: $main-margin-top-sm;
        margin-bottom: 120px;
        * {
            font-size: 20px; } }
    &__main-title {
        max-width: 300px; }
    &__title h3 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 1;
        font-weight: 400;
        @include md {
            // font-weight: bold
            margin-bottom: 20px;
            margin-top: 60px; } }
    &__title--high h3 {
        line-height: 1.5; }
    &__list {
        line-height: 28px;
        @include sm {
            color: $light-grey; } }
    &__text {
        line-height: 1.4;
        &--wide {
            line-height: 1.5;
            @include sm {
                padding-right: 27px; } } }
    &__list--muted {
        margin-top: 40px;
        color: $light-grey;
        @include md {
            margin-top: 98px; } }
    &__contacts {
        margin-bottom: 48px;
        @include md {
            margin-top: 40px; }
        @include sm {
            display: none; } }
    .adress {
        &:hover {
            .arrow {
                visibility: visible;
                opacity: 0;
                @include arrowTrans; } } } }





// ===== ROWS =====

.max-width-450 {
    max-width: 450px; }

.margin-bottom-200 {
    margin-bottom: 200px; }

.col-76 {
    .about__text {
        max-width: 440px; } }

.margin-bottom-94 {
    margin-bottom: 94px; }

.margin-bottom-45 {
    margin-bottom: 45px; }

.margin-bottom-80 {
    margin-bottom: 80px; }

.max-width-425 {
    max-width: 425px; }

.margin-bottom-68 {
    margin-bottom: 68px; }

.padding-right-50 {
    padding-right: 50px; }

.margin-bottom-30 {
    margin-bottom: 30px; }

.margin-bottom-65 {
    margin-bottom: 65px; }

.margin-bottom-170 {
    margin-bottom: 170px; }

.margin-bottom-lg-100 {
    @include lg {
        margin-bottom: 100px; } }


@include md {
    .about {
        &__main-title {
            margin-bottom: 42px; }
        [class*="margin-bottom"] {
            margin-top: 0;
            margin-bottom: 0; }
        .m-sm-10 {
            margin-bottom: -10px; } } }
