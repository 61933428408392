.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 8px;
    padding: 36px 0 10px;
    z-index: 3000;
    @include sm {
        position: relative; }
    .container-inner {
        @include sm {
            padding: 0 7px 0 15px; } }
    .container-inner {
        position: relative;
        z-index: 500; }
    &.fixed {
        position: fixed;
        right: 0; }
    @include sm {
        padding-top: 0; } }














