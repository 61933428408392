$white: #fff;
$light-grey: #abadb0;
$text-dark: #262626;
$orange: #ff4d00;
$main-menu-color: #b3b2b9;
$main-menu-color-muted: #646466;


// $padding_sm: 15px
$main-margin-top-sm: 124px;
$header-top-padding-sm: 16px;

$faktPro: 'FaktPro', Helvetica, Arial, sans-serif;

@mixin arrowTrans {
    transition: 0.4s opacity, 0.4s visibility; }
